<template>
  <div class="portfolio flex flex-col h-full">

    <app-breastfeeding-banner />

    <section class="bg-light flex-grow">
      <div class="container py-4 flex flex-col justify-start">

        <div class="flex flex-row justify-center align-middle pt-4 pb-8">
          <div
            class="filter-btn mx-4 py-2 px-10 rounded-full uppercase border-2 border-secondary
            bg-primary text-white font-bold text-sm cursor-pointer whitespace-nowrap"
            :class="{ active: prodFilter==='all' }"
            @click="prodFilter='all'"
          >
            Todos los productos
          </div>
          <div
            class="filter-btn mx-4 py-2 px-10 rounded-full uppercase border-2 border-secondary
            bg-primary text-white font-bold text-sm cursor-pointer"
            :class="{ active: prodFilter==='special' }"
            @click="prodFilter='special'"
          >
            Especiales
          </div>
          <div
            class="filter-btn mx-4 py-2 px-10 rounded-full uppercase border-2 border-secondary
            bg-primary text-white font-bold text-sm cursor-pointer"
            :class="{ active: prodFilter==='regular' }"
            @click="prodFilter='regular'"
          >
            Rutina
          </div>
        </div>
        
        <div class="py-8 flex flex-row flex-wrap justify-center">
          <div class="product" v-for="(product, idx) in filteredProducts" :key="`product-${idx}`">
            <div
              class="w-32 h-32 mx-4 my-4 p-4 rounded-full cursor-pointer
                text-center flex flex-col justify-end shadow hover:shadow-md"
              :style="{ backgroundColor: product.color }"
              @click="gotoProduct(product.slug)"
            >
              <img
                class="w-10/12 mx-auto"
                :src="product.image.packshotThumb"
                :alt="product.text.name"
              />
              <div
                class="product-name text-white text-sm leading-3 pt-1"
                v-html="product.text.name"
              />
            </div>
          </div>
        </div>

      </div>
    </section>

    <teleport to="#modals">
      <div v-if="showPortfolioAgreementPopup" class="fixed w-screen h-screen bg-black bg-opacity-50 flex flex-col justify-center items-center">
        <div class="container">
          <div class="p-8 rounded-lg bg-white shadow-lg flex flex-col justify-center items-center">
            <div class="mb-8 text-lg leading-5 text-primary text-justify">
              La lactancia materna proporciona la mejor nutrición para un crecimiento y desarrollo sano de los lactantes. Las madres deben de recibir orientación con respecto a la nutrición materna adecuada para ayudar a mantener un adecuado aporte y leche materna de calidad. La introducción innecesaria de alimentación con fórmula, parcial o completa, o de otros alimentos o bebidas complementarias pueden tener un efecto negativo en la lactancia materna, el cual puede ser irreversible. Las madres deben de consultar a su doctor y considerar las implicaciones sociales y económicas antes de decidir utilizar sustitutos de la leche materna o si tienen dificultar para amamantar. Se deben de seguir cuidadosamente las instrucciones de utilización, preparación y almacenamiento de los sustitutos de la leche materna ya que el uso inapropiado o innecesario pueden ser un riesgo para la salud. 
            </div>
            <div @click="agreePortfolio" class="mx-auto py-2 px-10 rounded-full uppercase border-2 border-secondary
            bg-primary text-white font-bold text-sm cursor-pointer whitespace-nowrap">
              He leído y lo entiendo
            </div>
          </div>
        </div>
      </div>
    </teleport> 
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import BreastfeedingBanner from "@/components/BreastfeedingBanner.vue";
import { getProducts } from "@/providers/products";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

type Dictionary = {
  [key: string]: any;
};

export default defineComponent({
  name: "Portfolio",
  components: {
    "app-breastfeeding-banner": BreastfeedingBanner,
  },
  setup() {
    const router = useRouter();
    const { getters, commit } = useStore();
    const products = getProducts();

    const prodFilter = ref("all");


    const filteredProducts = computed(() => {
      return products.filter(product => product.type === prodFilter.value || prodFilter.value === "all");
    });

    const showPortfolioAgreementPopup = computed(() => getters.showPortfolioAgreementPopup);

    const agreePortfolio = () => {
      commit("agreePortfolio");
    };

    const gotoProduct = (slug: string): void => {
      router.push({ name: 'Product', params: { slug } });
    }

    return { filteredProducts, gotoProduct, prodFilter, agreePortfolio, showPortfolioAgreementPopup };
  }
});
</script>

<style scoped lang="scss">
.filter-btn {
  &.active {
    background-color: white;
    color: var(--color-primary);
    @apply shadow-around;
  }
}
.product {
  transition: all .15s ease-out;
  transform-origin: center bottom;

  &:hover {
    transform: scale(1.1);
  }

  .product-name {
    min-height: 2em;
  }
}
</style>