
import { defineComponent, ref, computed } from "vue";
import BreastfeedingBanner from "@/components/BreastfeedingBanner.vue";
import { getProducts } from "@/providers/products";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

type Dictionary = {
  [key: string]: any;
};

export default defineComponent({
  name: "Portfolio",
  components: {
    "app-breastfeeding-banner": BreastfeedingBanner,
  },
  setup() {
    const router = useRouter();
    const { getters, commit } = useStore();
    const products = getProducts();

    const prodFilter = ref("all");


    const filteredProducts = computed(() => {
      return products.filter(product => product.type === prodFilter.value || prodFilter.value === "all");
    });

    const showPortfolioAgreementPopup = computed(() => getters.showPortfolioAgreementPopup);

    const agreePortfolio = () => {
      commit("agreePortfolio");
    };

    const gotoProduct = (slug: string): void => {
      router.push({ name: 'Product', params: { slug } });
    }

    return { filteredProducts, gotoProduct, prodFilter, agreePortfolio, showPortfolioAgreementPopup };
  }
});
